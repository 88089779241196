import axios from 'axios';
import "./common"
import {baseUrl, TIMEOUT, withCredentials} from "./common";

// 创建axios示例，这里设置请求的默认配置
const instance = axios.create({
    timeout: TIMEOUT,
    baseURL: baseUrl,
    withCredentials: withCredentials,  //cros请求默认不带cookie信息，如果想要包含在请求中必须将属性置为true。线上环境不带，开发环境带
})

// 文档统一设置请求头
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.get['Content-Type'] = 'application/json';

// 添加请求拦截器
instance.interceptors.request.use(config => {
    return config;
}, error => {
    return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(
    response => {
        if (response.status === 200) {
            // console.log("status 200", response)
            return Promise.resolve(response.data);
        } else if (response.status === 302) {
            // 浏览器会自动处理302的重定向，不会执行到这一步
            let redirectUrl = response.headers['location'];
            window.location.href = redirectUrl
        }
        return Promise.resolve(response.data.msg)
    },
    error => {
        if (error.response && error.response.status === 401) {
            const redirectUrl = error.response.headers['redirecturl'];
            if (redirectUrl) {
                // 重定向到返回的路径
                window.location.href = redirectUrl; // 或者使用 window.location.replace(redirectUrl);
            }
        } else if (error.response && error.response.status === 404) {
            window.location.href = baseUrl
        }
        return Promise.reject(error);
    }
)

export default instance;


