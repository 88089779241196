import React, {useEffect, useRef, useState} from 'react';
import {Row, Col, Table, message, Tag, Button, Card, Tabs, Input, Space, Modal} from 'antd';
import {
    AllBaseInfo,
    CheckAllBaseInfo,
    Draft,
    EditOptions,
    OutPatientProfile,
    TotalProfile, UpdateOneBaseInfo,
    UserProfile
} from "../common";
import type { ColumnsType } from 'antd/es/table';
import {CloudDownloadOutlined} from '@ant-design/icons'
import ProfileView from "./ProfileView";
import {
    calAge,
    fileDownLoad,
    getCurrentTimeStr,
    ListCondition,
    ListResponse,
    NationList
} from "./common";
import { SearchOutlined } from '@ant-design/icons';
import {BasicDataResponse, BasicResponse} from "../../../Http/common";
import handleAxios from "../../../Http/request"
import OutpatientView from "./OutpatientView";
type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

export default function () {

    const [loading, setLoading] = useState(true);

    const [options, setOptions] = useState<EditOptions>();

    // 消息提醒
    const [messageApi, contextHolder] = message.useMessage();

    // 选中的行信息，待生成病例。
    const [selectedUserProfiles
        , setSelectedUserProfiles] = useState<TotalProfile[]>([]);

    const [listResponse, setListResponse] = useState<ListResponse>();

    // 门诊病历生成
    const [selectedOutpatientProfiles, setSelectedOutpatientProfiles] = useState<OutPatientProfile[]>([]);

    /**
     * 拉取所有的配置选项
     */
    async function getAllConfigs () {
        let ret: Promise<BasicDataResponse<EditOptions>> = handleAxios.get("/api/getAllConfigs");
        ret.then((res) => {
            if (res.code === 0) {
                setOptions(res.data);
            } else {
                messageApi.open({
                    type:"error",
                    content: "拉取配置选项出错！"
                })
            }
        }).catch((error) => {
            // 只要发生异常，就返回重定向页面，大概率是session过期了
            messageApi.error("用户信息失效，请重新登陆");
            window.location.href="/login";
        })
    }

    const [listConditions, setListConditions] = useState<ListCondition>({
        limit: 10,
        offset: 0,
    })

    async function listProfiles () {
        setLoading(true);
        let ret: Promise<BasicDataResponse<ListResponse>> = handleAxios.post("/api/listProfiles",
            listConditions);
        ret.then((res) => {
            if (res.code === 0) {
                setListResponse(res.data);
                setLoading(false);
            } else {
                messageApi.error("拉取失败")
            }
        }).catch((error) => {
            console.log("拉取失败！")
        })
    }

    /**
     * 等接口数据加载完再渲染页面
     */
    useEffect(() => {
        getAllConfigs();
        listProfiles();
        setLoading(false);
    }, [])

    /**
     * 初始化病例详情
     * @param userRecord
     */
    function initTotalProfile(userRecord:UserProfile): TotalProfile {

        let newAge = calAge(userRecord.birthday, new Date());

        let retItem : TotalProfile= {
            age: newAge,
            startTime: "",
            afterDiagnosis: options?.afterDiagnosis[0] === undefined ? "" : options.afterDiagnosis[0],
            anesthesiologist: options?.anesthesiologist[0] === undefined ? "" : options.anesthesiologist[0],
            anestheticMode: options?.anestheticMode[0] === undefined ? "" : options.anestheticMode[0],
            beforeDiagnosis: options?.beforeDiagnosis[0] === undefined ? "" : options.beforeDiagnosis[0],
            endTime: "",
            firstAssistant: options?.firstAssistant[0] === undefined ? "" : options.firstAssistant[0],
            handWashingNurse: options?.handWashingNurse[0] === undefined ? "" : options.handWashingNurse[0],
            operator: options?.operator[0] === undefined ? "" : options.operator[0],
            rovingNurse: options?.operator[0] === undefined ? "" : options.rovingNurse[0],
            steps: {
                name: "",
                steps: []
            },
            strategyName: "",
            recordTime:"",
            ...userRecord

        }
        return retItem;
    }

    /**
     * 初始化生成一条门诊病历记录
     * @param userRecord
     */
    function initOutpatientProfile(userRecord:UserProfile):OutPatientProfile {
        let newAge = calAge(userRecord.birthday, new Date());
        let retItem : OutPatientProfile= {
            age: newAge,
            nation: NationList[0],          //默认是汉族
            doctor: options?.operator[0] === undefined ? "" : options.operator[0],
            createAt:"",
            clinicalDiagnosis: options?.beforeDiagnosis[0] === undefined ? "": options.beforeDiagnosis[0],
            allergicHistory: options?.allergicHistory[0] === undefined ? "" : options.allergicHistory[0],
            familyHistory: options?.familyHistory[0] === undefined ? "" : options.familyHistory[0],
            pastDiseaseHistory: options?.familyHistory[0] === undefined ? "" : options.pastDiseaseHistory[0],
            personHistory: options?.personHistory[0] === undefined ? "" : options.personHistory[0],
            physicalExamination: options?.physicalExamination[0] === undefined ? "" : options.physicalExamination[0],
            presentDiseaseHistory: options?.presentDiseaseHistory[0] === undefined ? "" : options.presentDiseaseHistory[0],
            principalComplaint: options?.principalComplaint[0] === undefined ? "" : options.principalComplaint[0],
            treatmentAdvice: options?.treatmentAdvice[0] === undefined ? "" : options.principalComplaint[0],
            recipes: {
                name: "",
                steps: []
            },
            ...userRecord
        }
        return retItem;
    }


    /**
     * 需要保存草稿的id列表
     * @param ids
     */
    async function saveAllDrafts(ids: number[]) {
        let allDraftObjectList: Draft[] = [];
        ids.forEach(id => {
            let isTotalProfile = selectedUserProfiles.find((item) => item.id === id);
            // 如果没有手术病例， 那么拉一个填充进草稿
            if (!isTotalProfile) {
                isTotalProfile = initTotalProfile(listResponse?.data.find((item) => item.id === id)!);
            }
            let isOutPatientProfile = selectedOutpatientProfiles.find((item) => item.id === id);
            // 如果没有生成门诊病例，那么从record中拉一个初始化填充进草稿
            if (!isOutPatientProfile) {
                isOutPatientProfile = initOutpatientProfile(listResponse?.data.find((item) => item.id === id)!)
            }
            allDraftObjectList.push({
                id: id,
                downLoadDocxRequest: isTotalProfile,
                downLoadOutPatientRequest: isOutPatientProfile
            })
        })
        if (allDraftObjectList.length === 0) {
            messageApi.info("没有选中对象，无需存草稿")
        } else {
            let ret: Promise<BasicResponse> = handleAxios.post("/api/updateRecord", allDraftObjectList);
            ret.then(data => {
                messageApi.success(data.data);
            }).catch((error) => {
                console.log(error.data);
                messageApi.error("保存草稿失败")
            })
        }
    }

    // 表头的所有列
    const tableHeader: ColumnsType<UserProfile> = [
        {
            title:"序号",
            dataIndex:"id",
            key:"id",
            width: 100,
            sorter: (a, b) => a.id - b.id,
        },
        {
            title:"患者ID",
            dataIndex: "userId",
            // key:"userId",
            width: 100,
            sorter: (a, b) => Number(a.userId) - Number(b.userId)
        },
        {
            title:"病案号",
            dataIndex: "profileId",
            // key:"profileId",
            width: 200,
            sorter:(a, b) => Number(a.profileId) - Number(b.profileId)
        },
        {
            title:"姓名",
            dataIndex: "name",
            // key:"name",
            width: 150,
        },
        {
            title:"生日",
            dataIndex: "birthday",
            // key:"birthday",
            width: 150
        },
        {
            title:"性别",
            dataIndex: "gender",
            // key:"gender",
            width: 120,
            onFilter: (value, record) => {
                return record.gender === value
            },
            sorter:(a, b) => a.gender.localeCompare(b.gender),
            filters: [
                {
                    text: "男",
                    value: "男",
                },
                {
                    text: "女",
                    value: "女"
                }
            ],
            render:(_, {gender}) => {
                if (gender == '男') {
                    return (
                        <Tag color="blue">
                            {gender}
                        </Tag>
                    )
                } else if (gender === '女') {
                    return (
                        <Tag color="magenta">
                            {gender}
                        </Tag>
                    )
                } else {
                    return (
                        <Tag >
                            空
                        </Tag>
                    )
                }
            }
        },
        {
            title:"备注",
            dataIndex: "ext",
            // key:"ext",
            ellipsis: true,
            render: (_, {ext}) =>
            {
                // 长度大于25字符的仅展示25字符
                let ret = "";
                if (ext === undefined) {
                    ret = "空"
                } else {
                    ret = ext;
                }
                return (
                    <>
                        {ret}
                    </>
                )
            }
        },
        {
            title: "修改人",
            key:"updateBy",
            dataIndex: "updateBy",
            width:80
        },
        {
          title: "修改时间",
            key: "updateAt",
          dataIndex: "updateAt",
            sorter: (a, b) => {
                if (!a.updateAt && !b.updateAt) {
                    return 0;
                } else if (!a.updateAt) {
                    return -1;
                } else if (!b.updateAt) {
                    return 1
                } else {
                    let dataA = new Date(a.updateAt);
                    let dateB = new Date(b.updateAt);
                    if (dataA < dateB) {
                        return -1;
                    } else if (dataA === dateB) {
                        return 0;
                    } else {
                        return 1;
                    }
                }
            }
        },
        {
            title: "操作",
            // key:"action",
            width: 400,
            render: (index, record) => {
                return (
                    <>

                        <Button
                            style={{
                                marginLeft:"5px"
                            }}
                            type="link"
                            onClick={() => {
                                let newUserProfiles = [...selectedUserProfiles];
                                // 检查对象是否已经在待撰写的病例列表中，如果在不添加，反之加入进去
                                let isExist = selectedUserProfiles.find((item) => item.id === record.id);
                                // console.log(isExist);
                                // 如果没找到或者不存在，那么加进选中的对象中
                                if (isExist === undefined || !isExist) {
                                    newUserProfiles.push(initTotalProfile(record));
                                }
                                setSelectedUserProfiles(newUserProfiles);
                            }}
                        >
                            撰写手术病例
                        </Button>
                        <Button
                            style={{
                                marginLeft:"5px"
                            }}
                            type="link"
                            onClick={() => {
                                // console.log("点击撰写门诊病历")
                                let newOutPatientProfiles = [...selectedOutpatientProfiles];
                                // 检查对象是否已经在待撰写的门诊病历列表中，如果在不添加，反之添加进去
                                let isExist = selectedOutpatientProfiles.find((item) => {
                                    return item.id === record.id
                                })
                                // console.log("门诊病历对象是否存在：", isExist)
                                if (isExist === undefined || !isExist) {
                                    newOutPatientProfiles.push(initOutpatientProfile(record))
                                }
                                setSelectedOutpatientProfiles(newOutPatientProfiles)
                            }}
                        >
                            撰写门诊病历
                        </Button>
                        {!!record.draft && (
                            <Button
                                style={{
                                    marginLeft:"5px",
                                    backgroundColor:"lightgoldenrodyellow"
                                }}
                                type="dashed"
                                onClick={() => {
                                    let newOutPatientsProfiles: OutPatientProfile[] = selectedOutpatientProfiles.filter(item => item.id !== record.id);
                                    if (record.draft?.downLoadOutPatientRequest) {
                                        newOutPatientsProfiles.push({...record.draft.downLoadOutPatientRequest})
                                    } else {
                                        newOutPatientsProfiles.push(initOutpatientProfile(record))
                                    }
                                    setSelectedOutpatientProfiles(newOutPatientsProfiles)

                                    // 检查对象是否已经在待撰写的病例列表中，如果在不添加替换内容为草稿内容
                                    let newUserProfiles:TotalProfile[] = selectedUserProfiles.filter(item=> item.id !== record.id);
                                    if (record.draft?.downLoadDocxRequest) {
                                        newUserProfiles.push({...record.draft.downLoadDocxRequest})
                                    } else {
                                        newUserProfiles.push(initTotalProfile(record))
                                    }
                                    setSelectedUserProfiles(newUserProfiles)
                                }}
                            >填充草稿</Button>
                        )}
                    </>
                )
            }
        }
        ];

    const onEdit = (
        targetKey: TargetKey,
        action: 'add' | 'remove',
    ) => {
        if (action === 'remove' && typeof targetKey === "string") {
            // 删除某个指定对象
            let newSelectedRowsArray = selectedUserProfiles.filter((temp) => String(temp.id) !== targetKey)
            setSelectedUserProfiles(newSelectedRowsArray)
        }
    };

    /**
     * 编辑门诊病历
     * @param targetKey
     * @param action
     */
    const onEditOutpatient = (
        targetKey: TargetKey,
        action: 'add' | 'remove',
    ) => {
        if (action === 'remove' && typeof targetKey === "string") {
            // 删除某个指定对象
            let newSelectedRowsArray = selectedOutpatientProfiles.filter((temp) => String(temp.id) !== targetKey)
            setSelectedOutpatientProfiles(newSelectedRowsArray)
        }
    };

    return (
        <>
            {contextHolder}

            <Card style={{width:"100%", marginTop:"3vh"}}>
                <Row justify="start" align="middle">
                    <Col span={2}>
                        患者ID
                    </Col>
                    <Col span={3}>
                        <Input
                            onChange={(e) => {
                                if (e.target.value === "") {
                                    setListConditions({...listConditions, userId: undefined})
                                    // listConditions.userId = undefined
                                } else {
                                    setListConditions({...listConditions, userId: Number(e.target.value)})
                                }
                            }}
                        />
                    </Col>
                    <Col span={2}>
                        病案号
                    </Col>
                    <Col span={3}>
                        <Input
                            onChange={(e) => {
                                if (e.target.value === "") {
                                    setListConditions({...listConditions, profileId: undefined})
                                    // listConditions.profileId = undefined;
                                } else {
                                    setListConditions({...listConditions, profileId: e.target.value})
                                    // listConditions.profileId = e.target.value;
                                }
                            }}
                        />
                    </Col>
                    <Col span={2}>
                        姓名
                    </Col>
                    <Col span={3}>
                        <Input
                            onChange={(e) => {
                                if (e.target.value === "") {
                                    // listConditions.name = undefined;
                                    setListConditions({...listConditions, name:undefined})
                                } else {
                                    setListConditions({...listConditions, name: e.target.value})
                                    // listConditions.name = e.target.value;
                                }
                            }}
                        />
                    </Col>
                    <Col span={2}>
                        性别
                    </Col>
                    <Col span={3}>
                        <Input
                            onChange={(e) => {
                                if (e.target.value === "") {
                                    setListConditions({...listConditions, gender: undefined})
                                    // listConditions.gender = undefined;
                                } else {
                                    setListConditions({...listConditions, gender: e.target.value})
                                    // listConditions.gender = e.target.value;
                                }
                            }}
                        />
                    </Col>
                    <Col span={4}>
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => {
                                console.log(listConditions);
                                listProfiles()
                            }}
                        >
                            查询
                        </Button>
                    </Col>
                </Row>
            </Card>
            <Table
                rowKey="id"
                style={{width:"100%", marginTop:"20px"}}
                pagination={
                    {
                        showSizeChanger: true,
                        total: listResponse?.total,
                        showTotal: (totalNum, _) => `共 ${totalNum } 条`,
                        onChange: (page, pageSize) => {
                            listConditions.limit = pageSize;
                            listConditions.offset = (page - 1) * pageSize;
                            listProfiles();
                        }
                    }
                }
                dataSource={listResponse?.data || []}
                columns={tableHeader}
                rowSelection={{
                    type:'checkbox',
                    onChange: (selectedRowKeys: React.Key[], selectedRows: UserProfile[]) => {
                        let newTotalProfile:TotalProfile[] = selectedRows.map((item) => initTotalProfile(item))
                        // console.log(newTotalProfile)
                        setSelectedUserProfiles(newTotalProfile);
                        let newOutPatientProfile: OutPatientProfile[] = selectedRows.map(item => initOutpatientProfile(item));
                        setSelectedOutpatientProfiles(newOutPatientProfile)
                    },
                    selectedRowKeys:selectedUserProfiles.map((item) => item.id),
                }}
            />
            {!loading && (
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <Card
                        title="已经选中的手术病例"
                        extra={
                            <>
                                 <Button
                                     style={{marginLeft:"10px"}}
                                     type="primary"
                                     icon={<CloudDownloadOutlined />}
                                     onClick={() => {
                                         if (selectedUserProfiles.length < 1) {
                                         return;
                                         } else {
                                         selectedUserProfiles.forEach((item) => {
                                         fileDownLoad(item);
                                         })
                                         }
                                         }}
                                     >下载所有手术病例
                                 </Button>
                                <Button
                                    type="primary"
                                    style={{marginLeft: "10px"}}
                                    onClick={() => {
                                        let allIds = selectedOutpatientProfiles.map(item=> item.id);
                                        saveAllDrafts(allIds)
                                    }}
                                >
                                    保存所有草稿
                                </Button>
                            </>
                        }
                    >
                        <Tabs
                            onEdit={onEdit}
                            hideAdd
                            type="editable-card"
                            items={selectedUserProfiles.map((item, mapIndex) => {
                                return {
                                    label: item.name,
                                    key: String(item.id),
                                    children: <ProfileView
                                            totalProfiles={item}
                                            options={options!}
                                            fatherChange={(newValue)=> {
                                                // console.log(newValue);
                                                let newTotalProfiles = [...selectedUserProfiles];   //深复制对象
                                                newTotalProfiles[mapIndex] = newValue;
                                                setSelectedUserProfiles(newTotalProfiles);
                                            }}
                                            saveDraft={() => {
                                                saveAllDrafts([item.id]);
                                            }}
                                        />
                                }
                            })}
                        />
                        </Card>
                    <Card
                        title="门诊病历">
                        <Tabs
                            onEdit={onEditOutpatient}
                            hideAdd
                            type={"editable-card"}
                            items={selectedOutpatientProfiles.map((item, mapIndex) => {
                                return {
                                    label: item.name,
                                    key: String(item.id),
                                    children: <OutpatientView
                                        totalProfiles={item}
                                        options={options!}
                                        fatherChange={(newValue) => {
                                            let newOutPatientProfiles = [...selectedOutpatientProfiles];
                                            newOutPatientProfiles[mapIndex] = newValue;
                                            setSelectedOutpatientProfiles(newOutPatientProfiles);
                                            // console.log("father change:", newValue)
                                        }}
                                        saveDraft={() => {
                                            saveAllDrafts([item.id]);
                                        }}
                                    />
                                }
                            })}
                        />
                    </Card>
                </Space>
            )}
        </>
    )
}
