import {Button, Card, Col, Divider, Input, message, Modal, Row, Table, Tabs, Tag, Typography} from "antd";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    AllBaseInfoListResponse,
    ConsumableFileDownLoad,
    fileDownLoad,
    getCurrentMSeconds,
    getCurrentTimeStr,
    InitSafetyCheckOfOperation,
    ListCondition,
    nursingRecordOfOperationDownload,
    SafetyCheckOfOperation,
    safetyCheckOfOperationDownload
} from "../Details/common";
import {SearchOutlined} from "@ant-design/icons";
import {ColumnsType} from "antd/es/table";
import {
    AllBaseInfo,
    CheckAllBaseInfo,
    Consumables,
    EditOptions,
    InitConsumablesRecord,
    InitNursingRecord,
    NursingRecord,
    SUBMIT_TYPE,
    TotalProfile,
    UpdateOneBaseInfo,
} from "../common";
import {BasicDataResponse} from "../../../Http/common";
import handleAxios from "../../../Http/request";
import SafetyCheck from "./SafetyCheck";
import NursingRecordOfOperation from "./NursingRecordOfOperation"
import Consumable from "./Consumable";
import BaseInfoEditor from "../../../componts/BaseInfoEditor";

const { Text, Link } = Typography

export default function () {
    // 消息提醒
    const [messageApi, contextHolder] = message.useMessage();

    const [options, setOptions] = useState<EditOptions>();
    // 基础信息编辑对话框
    const [editing, setEditing] = useState<AllBaseInfo | undefined>(undefined)

    const [timeStamp, setTimeStamp] = useState<number>(getCurrentMSeconds());
    /**
     * 获取一条记录的3张表格
     * @param record
     */
    async function getAllDraft(record: AllBaseInfo) {
        clearUseRefAndFresh();
        setLoading(true);
        await getSafetyCheckByProfileId(record);
        await getNursingRecordByProfileId(record);
        await getConsumableRecordByProfileId(record);
        setTimeStamp(getCurrentMSeconds());
        setLoading(false)
    }

    /**
     * 拉取所有的配置选项
     */
    async function getAllConfigs () {
        let ret: Promise<BasicDataResponse<EditOptions>> = handleAxios.get("/api/getAllConfigs");
        ret.then((res) => {
            if (res.code === 0) {
                setOptions(res.data);
            } else {
                messageApi.open({
                    type:"error",
                    content: "拉取配置选项出错！"
                })
            }
        }).catch((error) => {
            // 只要发生异常，就返回重定向页面，大概率是session过期了
            messageApi.error("用户信息失效，请重新登陆");
            window.location.href="/login";
        })
    }

    // 查询条件
    const [listConditions, setListConditions] = useState<ListCondition>({
        limit: 10,
        offset: 0,
    })
    // 表头的所有列
    const tableHeader: ColumnsType<AllBaseInfo> = [
        {
            title:"序号",
            dataIndex:"id",
            key:"id",
            width: 100,
            sorter: (a, b) => a.id - b.id,
        },
        {
            title:"病案号",
            dataIndex: "profileId",
            width: 200,
            sorter:(a, b) => Number(a.profileId) - Number(b.profileId)
        },
        {
            title:"姓名",
            dataIndex: "name",
            width: 150,
        },
        {
            title:"生日",
            dataIndex: "birthday",
            width: 120
        },
        {
            title:"性别",
            dataIndex: "gender",
            // key:"gender",
            width: 120,
            onFilter: (value, record) => {
                return record.gender === value
            },
            sorter:(a, b) => a.gender.localeCompare(b.gender),
            filters: [
                {
                    text: "男",
                    value: "男",
                },
                {
                    text: "女",
                    value: "女"
                }
            ],
            render:(_, {gender}) => {
                if (gender == '男') {
                    return (
                        <Tag color="blue">
                            {gender}
                        </Tag>
                    )
                } else if (gender === '女') {
                    return (
                        <Tag color="magenta">
                            {gender}
                        </Tag>
                    )
                } else {
                    return (
                        <Tag >
                            空
                        </Tag>
                    )
                }
            }
        },
        {
            title: "修改人",
            key:"updateBy",
            dataIndex: "updateBy",
            width:80
        },
        {
            title: "修改时间",
            key: "updateAt",
            dataIndex: "updateAt",
            width: 200,
            sorter: (a, b) => {
                if (!a.updateAt && !b.updateAt) {
                    return 0;
                } else if (!a.updateAt) {
                    return -1;
                } else if (!b.updateAt) {
                    return 1
                } else {
                    let dataA = new Date(a.updateAt);
                    let dateB = new Date(b.updateAt);
                    if (dataA < dateB) {
                        return -1;
                    } else if (dataA === dateB) {
                        return 0;
                    } else {
                        return 1;
                    }
                }
            }
        },
        {
            title:"备注",
            dataIndex: "ext",
            width: 150,
            // key:"ext",
            ellipsis: true,
            render: (_, {ext}) =>
            {
                // 长度大于25字符的仅展示25字符
                let ret = "";
                if (ext === undefined) {
                    ret = "空"
                } else {
                    ret = ext;
                }
                return (
                    <>
                        {ret}
                    </>
                )
            }
        },
        {
            title: "操作",
            // width: 400,
            render: (index, record) => {
                return (
                    <>
                        <Button
                            style={{
                                marginLeft:"5px"
                            }}
                            type="link"
                            onClick={() => {
                                setEditing(record)
                            }}
                        >
                            修改基础信息
                        </Button>
                        <Button
                            style={{marginLeft:"5px"}}
                            type="link"
                            onClick={() => {
                                // 初始化三张空白表
                                safetyCheckInfo.current = InitSafetyCheckOfOperation(record);
                                nursingRecord.current = InitNursingRecord(record);
                                consumableRecord.current = InitConsumablesRecord(record);
                                setCurrentBaseInfo(record)
                                // console.log("current safetyCheckOfOperation", safetyCheckInfo.current)
                                // console.log("current record:", record)
                                setTimeStamp(getCurrentMSeconds())
                            }}
                        >新建空白表</Button>
                        <Button
                            style={{marginLeft:"5px"}}
                            type="link"
                            onClick={async () => {
                                await getAllDraft(record);
                                setCurrentBaseInfo(record);
                            }}
                        >加载历史/草稿</Button>
                    </>
                )
            }
        }
    ];
    const [loading, setLoading] = useState(true)

    // 拉病人的所有基础信息接口
    async function listProfiles () {
        setLoading(true);
        let ret: Promise<BasicDataResponse<AllBaseInfoListResponse>> = handleAxios.post("/api/listProfiles",
            listConditions);
        ret.then((res) => {
            if (res.code === 0) {
                setListResponse(res.data);
                setLoading(false);
            } else {
                messageApi.error("拉取失败")
            }
        }).catch((error) => {
            console.log("拉取失败！")
        })
    }

    /**
     * 存储所有的基础信息
     */
    const [listResponse, setListResponse] = useState<AllBaseInfoListResponse>()

    /**
     * 当前正在编辑的基础信息
     */
    const [currentBaseInfo, setCurrentBaseInfo] = useState<AllBaseInfo | undefined>(undefined)

    const safetyCheckInfo = useRef<SafetyCheckOfOperation | undefined>(undefined)

    // 手术护理记录单
    const nursingRecord = useRef<NursingRecord | undefined>(undefined);

    // 记录所用材料明细
    const consumableRecord = useRef<Consumables | undefined>(undefined);


    // 控制当前默认激活的tab 的key
    const [currentActiveKey, setCurrentActiveKey] = useState<string>("0")

    // 清除所有useRef的值并刷新页面
    const clearUseRefAndFresh = () => {
        nursingRecord.current = undefined;
        consumableRecord.current = undefined;
        safetyCheckInfo.current = undefined;
    }

    /**
     * 存储所有草稿
     */
    const saveAllDraft = async () => {
        await saveSafetyCheckInfo(safetyCheckInfo.current, SUBMIT_TYPE.TYPE_SAVE_DRAFT);
        await saveNursingRecord(nursingRecord.current, SUBMIT_TYPE.TYPE_SAVE_DRAFT);
        await saveConsumableRecord(consumableRecord.current, SUBMIT_TYPE.TYPE_SAVE_DRAFT);
    }


    // 消耗明细变更
    const consumableOnChange = (v: Partial<Consumables>) => {
        let newConsumableRecord = {
            ...consumableRecord.current,
            ...v
        };
        // console.log(newConsumableRecord)
        consumableRecord.current = newConsumableRecord;
    }

    /**
     * 储存消耗明细表草稿
     * @param newDraft
     */
    async function saveConsumableRecord(newDraft: Consumables | undefined, submitType : SUBMIT_TYPE) {
        if (newDraft === undefined) {
            return;
        } else {
            let ret:Promise<Consumables> = handleAxios.post("/api/consumable/submit", {
                ...newDraft,
                submitType: submitType
            })
            await ret.then(data => {
                messageApi.success("消耗明细表保存成功")
            }).catch((error) => {
                messageApi.error("消耗明细保存失败:" + error.data);
            })
        }
    }

    /**
     * 保存护理记录单
     * @param newDraft
     * @param submitType
     */
    async function saveNursingRecord(newDraft: NursingRecord | undefined, submitType : SUBMIT_TYPE) {
        if (newDraft === undefined) {
            return;
        } else {
            let ret:Promise<NursingRecord> = handleAxios.post("/api/nursingRecord/submit", {
                ...newDraft,
                submitType: submitType
            })
            await ret.then(data => {
                messageApi.success("护理记录单保存成功")
            }).catch((error) => {
                messageApi.error("护理记录单保存失败:" + error.data);
            })
        }
    }

    /**
     * 保存或提交手术安全核查单
     * @param newDraft
     * @param submitType
     */
    async function saveSafetyCheckInfo(newDraft: SafetyCheckOfOperation | undefined, submitType: SUBMIT_TYPE) {
        if (newDraft === undefined) {
            return;
        } else {
            let ret:Promise<SafetyCheckOfOperation> = handleAxios.post("/api/safetyCheck/submit", {
                ...newDraft,
                submitType: submitType
            })
            await ret.then(data => {
                messageApi.success("手术安全核查单保存成功")
            }).catch((error) => {
                messageApi.error("手术安全核查单保存失败:" + error.data);
            })
        }
    }

    /**
     * 根据病例号拉取一条消耗明细
     * @param baseInfo
     */
    async function getConsumableRecordByProfileId(baseInfo: AllBaseInfo) {
        let ret:Promise<BasicDataResponse<Consumables>> =
            handleAxios.get("/api/consumable/getByProfileId?profileId=" + baseInfo.profileId);
        await ret.then((res) => {
            if (res.code !== 0) {
                messageApi.warning("拉取消耗明细表失败" + res.data);
            } else if (res.code === 0 && res.data !== null) {
                consumableRecord.current = res.data;
            } else {
                consumableRecord.current = InitConsumablesRecord(baseInfo);
            }
        })
    }

    /**
     * 根据病例ID拉取护理记录单
     * @param baseInfo
     */
    async function getNursingRecordByProfileId(baseInfo: AllBaseInfo) {
        let ret:Promise<BasicDataResponse<NursingRecord>> =
            handleAxios.get("/api/nursingRecord/getByProfileId?profileId=" + baseInfo.profileId);
        await ret.then((res) => {
            if (res.code !== 0) {
                messageApi.warning("拉取护理记录单失败" + res.data);
            } else if (res.code === 0 && res.data !== null) {
                nursingRecord.current = res.data;
            } else {
                nursingRecord.current = {
                    ...InitNursingRecord(baseInfo),
                    operator: options!.operator[0],
                    firstAssistant: options!.firstAssistant[0],
                    secondAssistant: options!.firstAssistant[0],
                    rovingNurse: options!.rovingNurse[0],
                    handWashingNurse: options!.handWashingNurse[0]
                }
            }
        })
    }

    /**
     * 根据病例ID拉取手术安全核查表信息
     * @param profileId
     */
    async function getSafetyCheckByProfileId(baseInfo: AllBaseInfo) {
        let ret:Promise<BasicDataResponse<SafetyCheckOfOperation>> =
            handleAxios.get("/api/safetyCheck/getSafetyCheck?profileId=" + baseInfo.profileId)
        await ret.then((res) => {
            if (res.code !== 0) {
                messageApi.warning("拉取手术安全核查单失败" + res.data);
            } else if (res.code === 0 && res.data !== null) {
                    safetyCheckInfo.current = res.data;
            } else {
                // 没有查到手术安全核查表的使用初始值
                safetyCheckInfo.current = {
                    ...InitSafetyCheckOfOperation(baseInfo),
                    recordTime: "",
                    strategyName: options!.strategyName[0],
                    operator: options!.operator[0],
                    anestheticMode: options!.anestheticMode[0]
                }
            }
            // messageApi.info("安全核查表拉取成功")
        }).catch((error) => {
            console.log(error)
            messageApi.error("拉取安全核查表失败")
        })
    }

    /**
     * 初始化执行汉书
     */
    const initFunc = useMemo(() => {
        setLoading(true)
        listProfiles();
        getAllConfigs();
        setLoading(false)
        console.log("initFunc 初始化")
    }, [])

    /**
     * 下载手术记录单
     */
    function downLoadOperationRecord() {
        if (nursingRecord.current == undefined) {
            messageApi.warning("手术护理记录单为空")
        } else {
            if (nursingRecord.current.steps == undefined) {
                messageApi.warning("手术步骤未选择");
            } else if (nursingRecord.current.entryTime.entryTime === undefined) {
                messageApi.warning("入室时间未选择，无法生成门诊病历记录时间")
            } else if (nursingRecord.current.entryTime.startTime === undefined) {
                messageApi.warning("手术开始时间未选择")
            } else if (nursingRecord.current.entryTime.endTime === undefined) {
                messageApi.warning("手术结束时间未选择")
            } else if (nursingRecord.current?.entryTime.leftTime === undefined) {
                messageApi.warning("离室时间未选择")
            } else {
                let startStr: string = nursingRecord.current.entryTime.entryTime.substring(0, 11)
                    + nursingRecord.current.entryTime.startTime + ":00";
                let endStr: string = nursingRecord.current.entryTime.entryTime.substring(0, 11)
                    + nursingRecord.current.entryTime.endTime + ":00";
                let recordStr : string = nursingRecord.current?.entryTime.entryTime.substring(0, 11)
                    + nursingRecord.current?.entryTime.leftTime + ":00";
                let downItem: TotalProfile = {
                    birthday: "",
                    ext: "",
                    gender: nursingRecord.current.gender,
                    id: 0,
                    name: nursingRecord.current.name,
                    profileId: nursingRecord.current.profileId,
                    userId: "",
                    afterDiagnosis: nursingRecord.current.beforeDiagnosis,
                    age: nursingRecord.current.age,
                    anesthesiologist: nursingRecord.current.anesthesiologist,
                    anestheticMode: nursingRecord.current.anestheticMode,
                    beforeDiagnosis: nursingRecord.current.beforeDiagnosis,
                    endTime: endStr,
                    firstAssistant: nursingRecord.current.firstAssistant,
                    handWashingNurse: nursingRecord.current.handWashingNurse,
                    operator: nursingRecord.current.operator,
                    recordTime: recordStr,
                    rovingNurse: nursingRecord.current.rovingNurse,
                    startTime: startStr,
                    steps: nursingRecord.current.steps,
                    strategyName: nursingRecord.current.strategyName
                }
                fileDownLoad(downItem)
            }
        }
    }

    /**
     * 下载消耗明细单
     */
    function downLoadConsumableRecord() {
        if (consumableRecord.current == undefined) {
            messageApi.warning("消耗明细单为空")
        } else {
            ConsumableFileDownLoad(consumableRecord.current);
        }
    }

    const submitModify = async () => {
        // 校验各个必须的字段是否都有值
        let checkRes = CheckAllBaseInfo(editing!);
        // 校验结果为空，那么可以直接提交
        if (checkRes !== "") {
            messageApi.open({
                type: "error",
                content:checkRes
            })
        } else {
            try {
                await UpdateOneBaseInfo(editing!)
                setEditing(undefined);
                await listProfiles();
                messageApi.success("修改成功")
            } catch (e) {
                messageApi.error("新增病人信息失败")
            }
        }
    }

    return (
        <>
            {contextHolder}
            {editing !== undefined && (
                <Modal
                    key={editing?.id}
                    title={'更新病人基础信息'}
                    width={'40vw'}
                    open={editing !== undefined}
                    onCancel={() => setEditing(undefined)}
                    onOk={() => {
                        submitModify();
                        listProfiles();
                    }
                    }
                >
                    <BaseInfoEditor
                        initValue={editing}
                        onChange={v => {
                            setEditing(v);
                            // console.log("upload file output:", v);
                        }}
                    />
                </Modal>
            )}
            <Card
                style={{
                    marginTop: "20px"
                }}
            >
                <Row justify="start" align="middle">
                    <Col span={2}>
                        病案号
                    </Col>
                    <Col span={3}>
                        <Input
                            onChange={(e) => {
                                if (e.target.value === "") {
                                    setListConditions({...listConditions, profileId: undefined})
                                } else {
                                    setListConditions({...listConditions, profileId: e.target.value})
                                }
                            }}
                        />
                    </Col>
                    <Col span={2}>
                        姓名
                    </Col>
                    <Col span={3}>
                        <Input
                            onChange={(e) => {
                                if (e.target.value === "") {
                                    setListConditions({...listConditions, name:undefined})
                                } else {
                                    setListConditions({...listConditions, name: e.target.value})
                                }
                            }}
                        />
                    </Col>
                    <Col span={2}>
                        性别
                    </Col>
                    <Col span={3}>
                        <Input
                            onChange={(e) => {
                                if (e.target.value === "") {
                                    setListConditions({...listConditions, gender: undefined})
                                } else {
                                    setListConditions({...listConditions, gender: e.target.value})
                                }
                            }}
                        />
                    </Col>
                    <Col span={4}>
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => {
                                // console.log("查询条件", listConditions);
                                clearUseRefAndFresh();
                                listProfiles();
                            }}
                        >
                            查询
                        </Button>
                    </Col>
                </Row>
            </Card>
            <Table
                rowKey="id"
                style={{width: "100%", marginTop: "20px"}}
                columns={tableHeader}
                dataSource={listResponse?.data || []}
                loading={loading}
                pagination={{
                    showSizeChanger: true,
                    total: listResponse?.total,
                    showTotal: (totalNum, _) => `共 ${totalNum } 条`,
                    onChange: (page, pageSize) => {
                        listConditions.limit = pageSize;
                        listConditions.offset = (page - 1) * pageSize;
                        clearUseRefAndFresh();
                        listProfiles();
                    }
                }}
            />
            {!loading
            && !!options
            && timeStamp
            && currentBaseInfo !== undefined
            && nursingRecord.current !== undefined
            && safetyCheckInfo.current !== undefined
            && consumableRecord.current !== undefined
            && (
                <>
                    <Card
                        key={String(timeStamp)} // 控制页面强制刷新
                        style={{marginTop: '9px', marginBottom: '40px'}}
                    >
                        <Tabs
                            defaultActiveKey={currentActiveKey}
                            onChange={(activeKey) => {
                                setCurrentActiveKey(activeKey)
                            }}
                            tabBarExtraContent={
                            <>
                            <Divider type={"vertical"} />
                            <Button
                                type={'link'}
                                onClick={() => {
                                    if (safetyCheckInfo.current === undefined) {
                                        messageApi.warning("手术安全核查表为空")
                                    } else {
                                        saveSafetyCheckInfo(safetyCheckInfo.current, SUBMIT_TYPE.TYPE_MODIFY)
                                        safetyCheckOfOperationDownload(safetyCheckInfo.current)
                                    }
                                }}
                            >
                                下载手术安全核查表
                            </Button>
                            <Divider type={"vertical"} />
                            <Button
                                type={'link'}
                                onClick={() => {
                                    // console.log("打印", nursingRecord)
                                    if (nursingRecord.current === undefined) {
                                        messageApi.warning("手术护理记录单为空")
                                    } else {
                                        saveNursingRecord(nursingRecord.current, SUBMIT_TYPE.TYPE_MODIFY)
                                        nursingRecordOfOperationDownload(nursingRecord.current)
                                    }
                                }}
                            >
                                下载手术护理记录单
                            </Button>
                            <Divider type={"vertical"} />
                            <Button
                                type={'link'}
                                onClick={() => {
                                    saveNursingRecord(nursingRecord.current, SUBMIT_TYPE.TYPE_MODIFY)
                                    downLoadOperationRecord()
                                }}
                            >
                                下载手术病历
                            </Button>
                            <Divider type={"vertical"} />
                            <Button
                                type={'link'}
                                onClick={() => {
                                    // console.log("打印所用材料明细:", consumableRecord.current)
                                    saveConsumableRecord(consumableRecord.current, SUBMIT_TYPE.TYPE_MODIFY)
                                    downLoadConsumableRecord()
                                }}
                            >
                                下载所用材料明细
                            </Button>
                            <Divider type={"vertical"} />
                            <Button
                                type="primary"
                                style={{marginLeft: "10px"}}
                                onClick={() => {
                                    saveAllDraft()
                                }}
                            >
                                保存所有草稿
                            </Button>
                        </>}
                        items={[
                            {
                                label:"手术安全核查表",
                                key:"0",
                                children:
                                    <SafetyCheck
                                        options={options}
                                        defaultValue={safetyCheckInfo.current}
                                        fatherChange={(newValue) => {
                                            safetyCheckInfo.current = newValue
                                            // console.log(newValue)
                                        }}
                                    />
                            },
                            {
                                label:"手术护理记录单",
                                key:"1",
                                children: <NursingRecordOfOperation
                                    defaultValue={nursingRecord.current}
                                    onChange={v => {
                                        nursingRecord.current = v;
                                    }}
                                    options={options}
                                />
                            },
                            {
                                label:"所用材料明细表",
                                key:"2",
                                children: <Consumable
                                    defaultValue={consumableRecord.current}
                                    options={options}
                                    onChange={consumableOnChange}
                                />
                            }
                        ]}/>
                    </Card>
                </>
                )
            }
        </>)
}